import { cloneDeep, Dictionary, isNullOrUndefined } from "table";

export interface FormHelperModel {
  required?: boolean;
  error?: string;
  validator?: (x: any) => { valid: boolean };
  type?: string;
  caption?: string;
}

class FormHelper {
  getErrors(modelDict: Dictionary<FormHelperModel>, data: Dictionary) {
    const newModelDict = cloneDeep(modelDict);
    const errors: FormHelperModel[] = [];
    Object.keys(modelDict).forEach((modelKey) => {
      function checkEmpty(value: any) {
        if (isNullOrUndefined(value) || value === "") {
          return true;
        }

        if (
          Array.isArray(value) &&
          ((value as Array<any>).findIndex(checkEmpty) !== -1 ||
            value.length === 0)
        ) {
          return true;
        }

        return false;
      }

      const model = newModelDict[modelKey];
      delete model.error;
      const value = data[modelKey];
      if (model.required && checkEmpty(value)) {
        model.error = "Обязательно для заполнения";
        errors.push(model);
      }

      if (model.validator) {
        const validatorResult = model.validator(value);
        if (!validatorResult.valid) {
          errors.push(model);
        }
      }
    });

    return {
      errors,
      newModelDict,
    };
  }
}

export default new FormHelper();
