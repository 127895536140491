
import { defineComponent, PropType, watch } from "vue";
import {
  ApiHelper,
  ApiService,
  ButtonComponent,
  CircleLoader,
  copy,
  Dictionary,
  HttpService,
  Logger,
  ModalComponent,
  NotificationsService,
  RepositoryReadModelDto,
  TABLE_CONSTS,
  TABLE_ICONS,
  TableModel,
  TInputComponent,
  TSelectComponent,
} from "table";
import ApiAReportsService from "@/modules/analytical-reports/services/ApiAReportsService/ApiAReportsService";
import FormHelper, { FormHelperModel } from "@/common/helpers/FormHelper";
import EditingVocEntry from "@/modules/editing-voc/components/EditingVocEntry.vue";
import { getValidErrorsAttrs } from "@/modules/editing-voc/common/helpers";
import {
  AReportDto,
  CreateAReportDto,
} from "@/modules/analytical-reports/common/types";
import { getValueOrOriginal } from "@/common/helpers/getValueOrOriginal";
import { VocRepositoryDto } from "table/dist/services/Api/types/VocRepositoryDto";
import { ApiSelectValue } from "table/dist/services/Api/types/ApiSelectValue";
import ApiActionsService from "@/services/ApiActions/ApiActionsService";

export type AReportRepositoryDto = VocRepositoryDto & {
  areport_params?: {
    areport_name: string;
    areport_type: string;
    url_get_params?: string;
    url_build?: string;
    url_rebuild?: string;
  };
};

export default defineComponent({
  name: "AnalyticalReportsCreateReport",
  props: {
    aReportDto: Object as PropType<AReportDto | undefined>,
    isRecalculate: Boolean,
    aReportParamsUrl: String,
  },
  components: {
    TInputComponent,
    ModalComponent,
    ButtonComponent,
    CircleLoader,
    TSelectComponent,
    EditingVocEntry,
  },
  emits: ["close", "success"],
  setup() {
    return {
      TABLE_ICONS,
    };
  },
  data() {
    return {
      model: {
        areport_type: {
          required: true,
        },
        areport_name: {
          required: true,
        },
      } as Dictionary<FormHelperModel>,
      formData: this.getInitFormData(),
      vocRepositoryDto: null as AReportRepositoryDto | null,
      aReportTypeOptions: [] as ApiSelectValue[],
    };
  },
  async created() {
    this.aReportTypeOptions = await ApiService.getSelectValues(
      "/api/repository/areports/types?action=list",
    ).then((x) => x.json.result);
    if (this.aReportDto) {
      this.formData = copy(this.aReportDto.data.areport_params);
    }

    watch(
      () => [this.formData.areport_type, this.aReportParamsUrl],
      () => {
        return this.initVocRepositoryDto();
      },
      { immediate: true },
    );

    watch(
      () => this.vocRepositoryDto?.areport_params?.areport_name,
      () => {
        this.formData.areport_name =
          this.vocRepositoryDto?.areport_params?.areport_name ?? "";
      },
    );
  },
  computed: {
    initValues(): {
      a_report_type?: string;
    } {
      const initValuesStr = this.queryParams.init_values;
      if (!initValuesStr) {
        return {};
      }

      try {
        return JSON.parse(initValuesStr);
      } catch {
        return {};
      }
    },
    queryParams(): {
      init_values?: string;
    } {
      return this.$route.query;
    },
  },
  methods: {
    async initVocRepositoryDto() {
      this.model.areport_type.required = !this.aReportParamsUrl;
      this.vocRepositoryDto = null;
      this.vocRepositoryDto = await this.getCurrentAReportParams();
    },

    getCurrentAReportParams() {
      if (this.aReportParamsUrl) {
        return HttpService.get<AReportRepositoryDto>(
          this.aReportParamsUrl,
        ).then((x) => x.json);
      }

      if (!this.formData.areport_type) {
        return null;
      }

      return ApiAReportsService.getAReportParams(
        getValueOrOriginal(this.formData.areport_type),
      ).then((x) => x.json);
    },

    getTypeAttr(model: TableModel) {
      return this.vocRepositoryDto?.types[model.type || ""];
    },

    onChangeField(event: { model: RepositoryReadModelDto; value: any }) {
      this.formData.attrs[event.model.field] = event.value;
    },

    onResetField(model: TableModel) {
      const type = this.getTypeAttr(model);
      this.formData.attrs[model.field] = type?.default || null;
    },

    getInitFormData() {
      return {
        areport_type: null as null | ApiSelectValue,
        areport_name: "",
        attrs: {} as Dictionary,
      };
    },
    async onCreateAReport() {
      if (!this.vocRepositoryDto) {
        Logger.warn("vocRepositoryDto is undefined");
        return;
      }

      const { errors, newModelDict } = FormHelper.getErrors(
        this.model,
        this.formData,
      );
      this.model = newModelDict;
      if (errors.length) {
        NotificationsService.send({
          type: "warn",
          title: "Проверка формы",
          text: "Найдены ошибки при заполнении.",
        });
        return;
      }

      const errorsAttrs = getValidErrorsAttrs(
        this.formData.attrs,
        this.vocRepositoryDto.form.table.model,
        this.vocRepositoryDto.types,
        TABLE_CONSTS.VALIDATION_HANDLERS,
      );
      if (errorsAttrs.length > 0) {
        NotificationsService.send({
          type: "warn",
          title: "Проверка формы",
          text: "Найдены ошибки при заполнении.",
        });
        return;
      }

      try {
        const params: CreateAReportDto = {
          areport_type:
            this.vocRepositoryDto.areport_params?.areport_type ?? "",
          areport_name: this.formData.areport_name,
          attrs: this.formData.attrs,
        };
        if (
          !this.isRecalculate &&
          this.vocRepositoryDto.areport_params?.url_build
        ) {
          const result = await ApiActionsService.executeExec(
            {
              url: this.vocRepositoryDto.areport_params.url_build,
              caption: "Создание аналитического отчёта",
            },
            params,
          );
          this.$emit("success", result);
          return;
        }

        if (
          this.isRecalculate &&
          this.vocRepositoryDto.areport_params?.url_rebuild
        ) {
          const result = await ApiActionsService.executeExec(
            {
              url: this.vocRepositoryDto.areport_params.url_rebuild,
              caption: "Пересчёт аналитического отчёта",
            },
            params,
          );
          this.$emit("success", result);
          return;
        }

        const result = this.isRecalculate
          ? await ApiAReportsService.recalculateAReport(
              this.aReportDto!.data.areport_id,
              params,
            )
          : await ApiAReportsService.createAReport(params);
        NotificationsService.send({
          type: "success",
          title: `Аналитический отчёт '${
            result.json.data.areport_name
          }' успешно ${this.isRecalculate ? "пересчитан" : "создан"}`,
          duration: 5000,
        });
        this.$emit("success", result.json);
        await this.$router.push(
          `/analytical-reports/` + result.json.data.areport_id,
        );
        // this.$emit("close");
      } catch (e: any) {
        NotificationsService.send({
          type: "error",
          title: "Произошла ошибка при создании",
          text: await ApiHelper.getErrorMessage(e),
        });
        Logger.error(e);
        return;
      }
    },
  },
});
